// Render Prop
import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Input, FieldContainer, Label } from './elements/Form';
import * as Yup from "yup";
import styled from 'styled-components';
import axios from 'axios'

const SignupSchema = Yup.object().shape({
    name: Yup.string()
        .max(100, 'Your name is too long')
        .required('Your name is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Your email is required'),
    message: Yup.string()
        .required('A message is required'),
});

export default class FormikForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: true,
            sent: false,
            error: false,
            resultMessage: false
        };
    }

    submitForm(formValues) {
        axios.post(`https://api.dboothdj.com/form`, formValues)
            .then(response => this.setState({
                error: true,
                showForm: true,
                resultMessage: <p className="form-success">Your message has been sent, thank you.</p>
            }))
            .catch(error => this.setState({
                sent: true,
                showForm: true,
                resultMessage: <p className="form-error">Something went wrong, your message wasn't sent. Please try again.</p>
            }));
        window.scrollTo(0, 0);
    }


    render() {
        return (
            <div>
                {this.state.resultMessage ? this.state.resultMessage : null}
                {this.state.showForm ? <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        message: ''
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(formValues) => this.submitForm(formValues)}
                >
                    <Form>
                        <label htmlFor="name">Name</label>
                        <FieldContainer>
                            <Field type="text" name="name" render={({ field /* _form */ }) => (
                                <input type="text" className="field" {...field} />
                            )} />
                            <ErrorMessage name="name" component={ErrorContainer} />
                        </FieldContainer>

                        <label htmlFor="name">Email</label>
                        <FieldContainer>
                            <Field type="email" name="email" render={({ field /* _form */ }) => (
                                <input type="text" className="field" {...field} />
                            )} />
                            <ErrorMessage name="email" component={ErrorContainer} />
                        </FieldContainer>

                        <label htmlFor="name">Message</label>
                        <FieldContainer>
                            <Field type="text" name="message" render={({ field /* _form */ }) => (
                                <textarea className="message-field" {...field}></textarea>
                            )} />
                            <ErrorMessage name="message" component={ErrorContainer} />
                        </FieldContainer>

                        <Label style={{
                            position: 'fixed',
                            top: '-200px',
                            display: 'none'
                        }}>Comments</Label>
                        <FieldContainer style={{
                            position: 'fixed',
                            top: '-200px',
                            display: 'none'
                        }}>
                            <Field type="text" name="comments" render={({ field /* _form */ }) => (
                                <Input {...field} />
                            )} />
                            <ErrorMessage name="comments" component={ErrorContainer} />
                        </FieldContainer>

                        <input type="submit" className="submit-button" value="Submit"/>
                    </Form>
                </Formik> : null}
            </div>
        );
    }
}

const ErrorContainer = styled.div`
    color: #ff0000;
    margin-top: -10px;
    margin-bottom: 10px;
    font-weight: bold;
    @media only screen and (max-width: 960px) {
        margin-left: 3%;
        margin-right: 3%;
    }
`
