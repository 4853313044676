import styled from "styled-components";

export const Form = styled.form`
  display: block;
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 10px;
  font-family: "Comfortaa", cursive;
  font-size: 14px;
`;

export const Input = styled.input`
  width: 100%;
  display: block;
  margin: 0 0 5px 0;
  padding: 10px;
  border: solid 1px #ddd;
`;

export const TextArea = styled.textarea`
  width: 100%;
  display: block;
  margin: 0 0 5px 0;
  padding: 10px;
  border: solid 1px #ddd;
  height: 120px;
`;

export const FormError = styled.div`
  width: 100%;
  color: red;
  font-size: 12px;
  font-weight: bold;
  // margin-top: 20px;
`;

export const FieldContainer = styled.div`
  display: block;
`;
