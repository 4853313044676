import React from 'react';
import { graphql } from "gatsby"
import LegacyContainer from '../components/legacy-container';
import SEO from '../components/SEO';
import FormikForm from '../components/FormikForm';
import PageTitle from '../components/modular/PageTitle/PageTitle';

export default function Contact({ data }) {
  const content = data.allMarkdownRemark.edges[0].node.frontmatter;
  return (
    <LegacyContainer>
      <SEO pageTitle={content.heading} />
      <div className="content-container brighter-content-container">
        <div className="content">
          <PageTitle title={content.heading} />
          <div className="contact-form-container">
            <div className="form-fields">
              <FormikForm />
            </div>
          </div>

          <div className="contact-details-container">
            <h1>Contact details</h1>
            <p>M. {content.phone}</p>
            <p>E. <a href={`mailto:${content.email}`}>{content.email}</a></p>
          </div>
          <div className="clear"></div>
        </div>
      </div>
    </LegacyContainer>
  );
}

export const testimonialsQuery = graphql`
  query ContactQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "contact-page" } }}
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            heading
            email
            phone
          }
        }
      }
    }
  }
`